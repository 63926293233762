import React from 'react';
import './index.css';
import Main from "./layouts/app-main";
import {configureStore} from './redux/store';
import {createRoot} from 'react-dom/client';
import {Provider} from "react-redux";

const store = configureStore();
const rootElement = document.getElementById("root");

const renderApp = (Component) => {
    createRoot(rootElement).render(
        <Provider store={store}>
            <Component />
        </Provider>,
    );
};

renderApp(Main);
