import {AUTH_CLEAR, AUTH_INIT, AUTH_SET,} from "../actions";

const INIT_STATE = {
    token: "",
    auth: false,
    user: null,
};

export default function authUser(state = INIT_STATE, action) {
    switch (action.type) {
        case AUTH_INIT:
            return {
                ...state,
                auth: action.payload.auth,
                token: action.payload.token,
                user: action.payload.user,
            };

        case AUTH_SET:
            localStorage.setItem("NotainPOS-Admin-Auth", true);
            localStorage.setItem("NotainPOS-Admin-Auth-Key", action.payload.token);
            localStorage.setItem(
                "NotainPOS-Admin-Auth-User",
                JSON.stringify(action.payload.user),
            );

            return {
                ...state,
                auth: true,
                token: action.payload.token,
                user: action.payload.user,
            };

        case AUTH_CLEAR:
            localStorage.clear();

            return {
                ...state,
                auth: false,
                token: "",
                user: null, // Reset user to null for consistency
            };
        default:
            return state;
    }
}
