import { HomeIcon,DocumentTextIcon, UserIcon, BuildingOfficeIcon, InboxIcon, CurrencyDollarIcon, ArchiveBoxIcon, PlusCircleIcon, CogIcon, DocumentIcon } from '@heroicons/react/24/outline';

const data = [
    {
        id: "dashboard",
        icon: <HomeIcon className="w-5 h-5" />,
        label: "Dashboard",
        to: 'dashboard',
        subs: []
    },
    {
        id: "account",
        icon: <UserIcon className="w-5 h-5" />,
        label: "Account",
        to: '/account',
        subs: []
    },
    {
        id: "business-fields",
        icon: <DocumentIcon className="w-5 h-5" />,
        label: "Business Sectors",
        to: "",
        subs: [
            {
                icon: <ArchiveBoxIcon className="w-5 h-5" />,
                label: "Business Sector List",
                to: '/business-fields/list',
                subs: []
            },
            {
                icon: <PlusCircleIcon className="w-5 h-5" />,
                label: "Add Business Sector",
                to: '/business-fields/add',
                subs: []
            },
        ],
    },
    {
        id: "blog",
        icon: <DocumentTextIcon className="w-5 h-5" />,
        label: "Blog",
        to: "",
        subs: [
            {
                icon: <ArchiveBoxIcon className="w-5 h-5" />,
                label: "Blog List",
                to: '/blog/list',
                subs: []
            },
            {
                icon: <PlusCircleIcon className="w-5 h-5" />,
                label: "Create Blog Post",
                to: '/blog/add',
                subs: []
            },
        ],
    },
    {
        id: "company",
        icon: <BuildingOfficeIcon className="w-5 h-5" />,
        label: "Company",
        to: '/company/list',
        subs: []
    },
    {
        id: "inbox",
        icon: <InboxIcon className="w-5 h-5" />,
        label: "Inbox",
        to: '/inbox',
        subs: []
    },
    {
        id: "langganan",
        icon: <CurrencyDollarIcon className="w-5 h-5" />,
        label: "Subscriptions",
        to: '/subscriptions',
        subs: []
    },
    {
        id: "paket",
        icon: <DocumentIcon className="w-5 h-5" />,
        label: "Packages",
        to: "",
        subs: [
            {
                icon: <ArchiveBoxIcon className="w-5 h-5" />,
                label: "Package List",
                to: '/paket/list',
                subs: []
            },
            {
                icon: <PlusCircleIcon className="w-5 h-5" />,
                label: "Create New Package",
                to: '/paket/tambah',
                subs: []
            },
        ],
    },
    {
        id: "user",
        icon: <UserIcon className="w-5 h-5" />,
        label: "Users",
        to: "",
        subs: [
            {
                icon: <ArchiveBoxIcon className="w-5 h-5" />,
                label: "User List",
                to: '/user/list',
                subs: []
            },
            {
                icon: <PlusCircleIcon className="w-5 h-5" />,
                label: "Create New User",
                to: '/user/tambah',
                subs: []
            },
        ],
    },
    {
        id: "setting",
        icon: <CogIcon className="w-5 h-5" />,
        label: "Settings",
        to: '/setting',
        subs: []
    }
];

export default data;
