import React from "react";
import {NavLink} from "react-router-dom";
import {Field, Form, Formik} from "formik";
import {Button, Card, CardTitle, FormGroup, Label, Row,} from "@material-tailwind/react";

const validateNewPassword = (values) => {
    const { newPassword, newPasswordAgain } = values;
    const errors = {};
    if (newPasswordAgain && newPassword !== newPasswordAgain) {
        errors.newPasswordAgain = "Please check your new password";
    }
    return errors;
};

const ResetPassword = () => {
    const loading = false;

    return (
        <Row className="h-100">
            <div className="mx-auto my-auto">
                <Card className="auth-card">
                    <div className="position-relative image-side ">
                        <p className="h2 text-white">MAGIC IS IN THE DETAILS</p>
                        <p className="white mb-0">
                            Please use your e-mail to reset your password.{" "}
                            <br />
                            If you are not a member, please{" "}
                            <NavLink to="/register" className="white">
                                register
                            </NavLink>
                            .
                        </p>
                    </div>
                    <div className="form-side">
                        <NavLink to="/" className="white">
                            <span className="logo-single" />
                        </NavLink>
                        <CardTitle className="mb-4">Reset Password</CardTitle>

                        <Formik validate={validateNewPassword}>
                            {({ errors, touched }) => (
                                <Form className="av-tooltip tooltip-label-bottom">
                                    <FormGroup className="form-group has-float-label">
                                        <Label>Password baru</Label>
                                        <Field
                                            className="form-control"
                                            name="newPassword"
                                            type="password"
                                        />
                                    </FormGroup>
                                    <FormGroup className="form-group has-float-label">
                                        <Label>Masukkan Password lagi</Label>
                                        <Field
                                            className="form-control"
                                            name="newPasswordAgain"
                                            type="password"
                                        />
                                        {errors.newPasswordAgain &&
                                            touched.newPasswordAgain && (
                                                <div className="invalid-feedback d-block">
                                                    {errors.newPasswordAgain}
                                                </div>
                                            )}
                                    </FormGroup>

                                    <div className="d-flex justify-content-between align-items-center">
                                        <NavLink to="/user/login">
                                            Login
                                        </NavLink>
                                        <Button
                                            color="primary"
                                            className={`btn-shadow btn-multiple-state ${
                                                loading ? "show-spinner" : ""
                                            }`}
                                            size="lg"
                                        >
                                            <span className="spinner d-inline-block">
                                                <span className="bounce1" />
                                                <span className="bounce2" />
                                                <span className="bounce3" />
                                            </span>
                                            <span className="label">
                                                Reset Password
                                            </span>
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Card>
            </div>
        </Row>
    );
};

export default ResetPassword;
