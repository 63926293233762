import React from "react";

const SvgIcon = ({
  width = 40,
  height = 40,
  className = "fill-white",
  opacity = "1.0",
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.6667 9.99984C36.6667 8.1665 35.1667 6.6665 33.3333 6.6665H6.66667C4.83333 6.6665 3.33333 8.1665 3.33333 9.99984V29.9998C3.33333 31.8332 4.83333 33.3332 6.66667 33.3332H33.3333C35.1667 33.3332 36.6667 31.8332 36.6667 29.9998V9.99984ZM33.3333 9.99984L20 18.3332L6.66667 9.99984H33.3333ZM33.3333 29.9998H6.66667V13.3332L20 21.6665L33.3333 13.3332V29.9998Z"
        fillOpacity={opacity}
      />
    </svg>
  );
};

export default SvgIcon;
