// src/redux/store.js

import { applyMiddleware, compose, createStore } from "redux";
import reducers from "./reducers";
import { thunk } from "redux-thunk";

const middlewares = [thunk];

export function configureStore(initialState) {
    const store = createStore(
        reducers,
        initialState,
        compose(applyMiddleware(...middlewares)),
    );

    if (module.hot) {
        module.hot.accept("./reducers", () => {
            const nextRootReducer = require("./reducers").default;
            store.replaceReducer(nextRootReducer);
        });
    }

    return store;
}
