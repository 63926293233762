import React from 'react';

const NotFound = () => {
    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
            <div className="text-center">
                <h1 className="text-6xl font-bold text-primary">404</h1>
                <h2 className="mt-2 text-2xl font-bold text-gray-800">
                    Halaman Tidak Ditemukan
                </h2>
                <p className="mt-4 text-gray-600">
                    Maaf, halaman yang Anda cari tidak dapat ditemukan.
                </p>
            </div>
        </div>
    );
};

export default NotFound;
