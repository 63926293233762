import React, {useState} from "react";

import TopNav from "./header";
import Footer from "./footer-main";
import menuItems from "route/menu";
import Sidebar from "./sidebar/sidebar";
import {Outlet} from "react-router-dom";

const AppLayout = ({ request }) => {
    const [show, setShow] = useState(true);

    const toggle = () => {
        setShow(!show);
    };

    return (
        <div className="flex h-screen">
            {/* Sidebar */}
            <Sidebar
                menuItems={menuItems}
                toggle={toggle}
                show={show}
            />

            {/* Main content wrapper */}
            <div
                className={`flex flex-1 transform flex-col overflow-y-auto duration-300 ease-in ${
                    show ? "md:ml-64" : "md:ml-16"
                }`}
            >
                <TopNav
                    show={show}
                    toggle={toggle}
                />

                <main className="p-6">
                    {/*{children}*/}
                    <Outlet context={[(url, body, method) => request(url, body, method)]}/>
                </main>

                {/* Footer */}
                <Footer />
            </div>
        </div>
    );
};

export default AppLayout;
