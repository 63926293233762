import React, {useEffect, useRef, useState} from "react";
import {NotificationManager} from "../../components/react-notifications";

const OTPInput = ({
    value = "",
    numInputs = 4,
    onChange,
    shouldAutoFocus = false,
    inputType = "text",
    renderSeparator,
    placeholder,
}) => {
    const [activeInput, setActiveInput] = useState(0);
    const inputRefs = useRef(Array(numInputs).fill(null));
    const isInputNum = inputType === "number" || inputType === "tel";

    const getOTPValue = () => (value ? value.toString().split("") : []);

    useEffect(() => {
        inputRefs.current = inputRefs.current.slice(0, numInputs);
    }, [numInputs]);

    useEffect(() => {
        if (shouldAutoFocus) {
            inputRefs.current[0]?.focus();
        }
    }, [shouldAutoFocus]);

    const getPlaceholderValue = () => {
        if (typeof placeholder === "string") {
            if (placeholder.length === numInputs) {
                return placeholder;
            }

            if (placeholder.length > 0) {
                NotificationManager.error(
                    "Length of the placeholder should be equal to the number of inputs.",
                    "Error",
                    3000,
                    null,
                    null,
                    "",
                );
            }
        }
        return undefined;
    };

    const isInputValueValid = (inputVal) => {
        const isTypeValid = typeof inputVal === "string";
        const isNumber = /^\d+$/.test(inputVal);

        return isTypeValid && isNumber && inputVal.trim().length === 1;
    };

    const handleOTPChange = (otp) => {
        const otpValue = otp.join("");
        onChange(otpValue);
    };

    const changeCodeAtFocus = (val) => {
        const otp = getOTPValue();
        otp[activeInput] = val[0];
        handleOTPChange(otp);
    };

    const focusInput = (index) => {
        const activeIpt = Math.max(Math.min(numInputs - 1, index), 0);

        if (inputRefs.current[activeIpt]) {
            inputRefs.current[activeIpt]?.focus();
            inputRefs.current[activeIpt]?.select();
            setActiveInput(activeIpt);
        }
    };

    const handleChange = (event) => {
        const inputValue = event.target.value;

        if (isInputValueValid(inputValue)) {
            changeCodeAtFocus(inputValue);
            focusInput(activeInput + 1);
        }
    };

    const handleFocus = (event, index) => {
        setActiveInput(index);
        event.target.select();
    };

    const handleBlur = () => {
        setActiveInput(activeInput - 1);
    };

    const handleKeyDown = (event) => {
        const otp = getOTPValue();
        if (["Backspace", "Delete"].includes(event.code)) {
            event.preventDefault();
            changeCodeAtFocus("");
            focusInput(activeInput - 1);
        } else if (event.code === "ArrowLeft") {
            event.preventDefault();
            focusInput(activeInput - 1);
        } else if (event.code === "ArrowRight") {
            event.preventDefault();
            focusInput(activeInput + 1);
        } else if (event.key === otp[activeInput]) {
            event.preventDefault();
            focusInput(activeInput + 1);
        } else if (
            ["Spacebar", "Space", "ArrowUp", "ArrowDown"].includes(event.code)
        ) {
            event.preventDefault();
        }
    };

    const handlePaste = (event) => {
        event.preventDefault();

        const otp = getOTPValue();
        const nextActiveInput = numInputs - 1;

        const pastedData = event.clipboardData
            .getData("text/plain")
            .slice(0, numInputs)
            .split("");

        if (
            isInputNum &&
            pastedData.some((newVal) => Number.isNaN(Number(newVal)))
        ) {
            return;
        }

        for (let pos = 0; pos < numInputs; pos += 1) {
            if (pastedData.length > 0) {
                otp[pos] = pastedData.shift() || "";
            }
        }

        focusInput(nextActiveInput);
        handleOTPChange(otp);
    };

    return (
        <div
            className="flex flex-row items-center justify-center gap-x-3"
            onPaste={handlePaste}
        >
            {Array.from({ length: numInputs }, (_, index) => index).map(
                (index) => (
                    <div key={index}>
                        <input
                            key={index}
                            className="max-w-[70px] rounded-md border border-gray-900 border-opacity-35 py-1.5 text-center focus:outline-primary"
                            value={getOTPValue()[index] ?? ""}
                            placeholder={getPlaceholderValue()?.[index] ?? ""}
                            ref={(element) =>
                                (inputRefs.current[index] = element)
                            }
                            onChange={handleChange}
                            onFocus={(e) => handleFocus(e, index)}
                            onBlur={handleBlur}
                            onKeyDown={handleKeyDown}
                            onPaste={handlePaste}
                            autoComplete="off"
                        />

                        {index < numInputs - 1 &&
                            (typeof renderSeparator === "function"
                                ? renderSeparator(index)
                                : renderSeparator)}
                    </div>
                ),
            )}
        </div>
    );
};

export default OTPInput;
