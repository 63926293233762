import React, {Component} from "react";
import {NavLink} from "react-router-dom";
import Swal from "sweetalert2";
import {requestData} from "../../config/request";
import {Card} from "@material-tailwind/react";

class Confirm extends Component {
    constructor() {
        super();

        this.state = {
            isLoading: false,
        };
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        const { match } = this.props;

        const form = {
            token: match.params.token,
        };

        requestData("account/aktivasi", form, "POST").then((respon) => {
            if (respon.success) {
                Swal.fire({
                    title: "Berhasil!",
                    text: respon.message,
                    icon: "success",
                });
            } else {
                Swal.fire({
                    title: "Gagal!",
                    text: respon.message,
                    icon: "warning",
                    confirmButtonText: "OK",
                });
            }
        });
    };

    render() {
        return (
            <>
                <div className="fixed-background" />
                <main>
                    <div className="container">
                        <div className="h-100">
                            <div className="mx-auto my-auto">
                                <Card className="auth-card">
                                    <div className="position-relative image-side ">
                                        <p className="h2 text-white">
                                            SELAMAT BERGABUNG DENGAN GUGU
                                        </p>
                                        <p className="white mb-0">
                                            cara mudah memantau usaha anda
                                        </p>
                                    </div>
                                    <div className="form-side">
                                        <NavLink to="/" className="white">
                                            <span className="logo-single" />
                                        </NavLink>
                                        <div className="mb-4">
                                            <h2 className="d-flex justify-content-center">
                                                Akun anda telah terverikasi
                                            </h2>
                                            <div className="d-flex justify-content-center">
                                                <div
                                                    id="Tooltip-6"
                                                    className="glyph-icon simple-icon-check"
                                                    style={{
                                                        color: "#0dbf0d",
                                                        fontSize: "100px",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <NavLink
                                                to="/app"
                                                className="btn btn-primary btn-shadow btn-lg"
                                            >
                                                Login
                                            </NavLink>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

export default Confirm;
