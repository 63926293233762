import React, {useState} from "react";
import {Navigate, NavLink} from "react-router-dom";
import {connect} from "react-redux";
import Cookies from "js-cookie";
import {Field, Form, Formik} from "formik";
import {NotificationManager} from "../../components/react-notifications";
import {authSet} from "../../redux/actions";

import {requestData} from "../../config/request";
import OTPInput from "./otp-verification";
import {CircleXIcon, EmailIcon, EyeIcon, EyeOffIcon, LockedIcon,} from "../../assets/icon";
import laptopShadow from "../../assets/illustration/login/laptop.png";
import notainLogo from "../../assets/logo/notain-logo.png";
import {URL_AUTH} from "../../config/host";

const validatePassword = (value) => {
    let error;

    if (!value) error = "Masukkan password anda";
    else if (value.length < 4) error = "Password minimal 4 karakter";

    return error;
};

const validateEmail = (value) => {
    let error;

    if (!value) error = "Masukkan alamat email anda";
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value))
        error = "Email yang anda masukkan salah";

    return error;
};

const Login = ({ loginUser, auth }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [messageError, setMessageError] = useState("");
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [otp, setOtp] = useState("");
    const [confirm, setConfirm] = useState(false);
    const [loading, setLoading] = useState(false);

    const requestCode = (values) => {
        if (!loading) {
            if (values.email !== "" && values.password !== "") {
                setLoading(true);
                setEmail(values.email);
                setPassword(values.password);

                requestData("user/req-code", {
                    email: values.email,
                    password: values.password,
                }).then((response) => {
                    if (response.success) {
                        NotificationManager.success(
                            response.message,
                            "Berhasil!",
                            3000,
                            null,
                            null,
                            "",
                        );
                        setConfirm(true);
                        setMessageError("");
                        // loginUser(response.data.token, response.data)
                    } else {
                        // NotificationManager.warning(response.message, 'Gagal!', 3000, null, null, '')
                        setMessageError(response.message);
                    }

                    setLoading(false);
                });
            }
        }
    };

    const requestAuth = () => {
        if (!loading) {
            if (email !== "" && password !== "" && otp !== "") {
                setLoading(true);

                requestData("user/login", { email, password, otp }).then(
                    (response) => {
                        if (response.success) {
                            NotificationManager.success(
                                response.message,
                                "Berhasil!",
                                3000,
                                null,
                                null,
                                "",
                            );
                            Cookies.set("logged", true, {
                                domain: URL_AUTH,
                                secure: true,
                                sameSite: "None",
                            });
                            Cookies.set("user", response.data.name, {
                                domain: URL_AUTH,
                                secure: true,
                                sameSite: "None",
                            });
                            setConfirm(false);
                            loginUser(response.data.token, response.data);
                        } else {
                            setOtp("");
                            NotificationManager.warning(
                                response.message,
                                "Gagal!",
                                3000,
                                null,
                                null,
                                "",
                            );
                        }

                        setLoading(false);
                    },
                );
            }
        }
    };

    React.useEffect(() => {
        if (otp.length === 6) {
            requestAuth();
        }
    }, [otp]);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const initialValues = { email, password };

    if (auth) {
        return <Navigate to='/dashboard' replace />;
    }

    return (
        <div className="container mx-auto w-full justify-center p-[30px] lg:p-[70px]">
            <div className="flex flex-row rounded-2xl bg-white ">
                <div className="flex w-full flex-col overflow-auto px-10 py-10 lg:px-20">
                    <div className="flex justify-center">
                        <img
                            width={161}
                            height={49}
                            src={notainLogo}
                            alt="logo"
                        />
                    </div>
                    <div className="mb-3.5 mt-4 flex items-center justify-center">
                        {confirm ? (
                            <span className="font-semibold">
                                Tolong masukkan Kode OTP untuk mengonfirmasi
                                proses login Anda.
                            </span>
                        ) : messageError ? (
                            <div className="flex flex-row gap-x-1.5 rounded-md border border-[#E03616] border-opacity-25 bg-[#E03616] bg-opacity-10 px-3.5 py-1.5">
                                <CircleXIcon />
                                <div className="text-[14px] font-medium text-[#E03616]">
                                    {messageError}
                                </div>
                            </div>
                        ) : (
                            <label className="font-bold text-black">
                                Masuk Akun
                            </label>
                        )}
                    </div>
                    {confirm ? (
                        <OTPInput value={otp} onChange={setOtp} numInputs={6} />
                    ) : (
                        <Formik
                            initialValues={initialValues}
                            onSubmit={requestCode}
                        >
                            {({ errors, touched }) => (
                                <Form className="flex flex-col">
                                    <div className="gap-y-1.5">
                                        <div className="flex flex-col gap-y-1.5">
                                            <label className="font-medium text-black">
                                                Email
                                            </label>
                                            <div className="relative">
                                                <EmailIcon
                                                    opacity="0.25"
                                                    className={`absolute left-3 top-1/2 -translate-y-1/2 transform text-gray-600 ${
                                                        errors.email
                                                            ? "fill-[#E03616]"
                                                            : "fill-[#444444]"
                                                    }`}
                                                    width={16}
                                                    height={18}
                                                />
                                                <Field
                                                    placeholder="Email Address"
                                                    className={`placeholder-gray w-full rounded-md border border-[#444444] border-opacity-25 p-2.5 pl-8 text-[14px] font-medium placeholder-opacity-25 placeholder:text-[14px] placeholder:font-medium focus:outline-primary ${
                                                        errors.email &&
                                                        "border-[#E03616] border-opacity-100"
                                                    }`}
                                                    name="email"
                                                    validate={validateEmail}
                                                />
                                            </div>
                                        </div>

                                        {errors.email && touched.email && (
                                            <div className="text-[12px] font-medium text-[#E03616]">
                                                {errors.email}
                                            </div>
                                        )}
                                    </div>

                                    <div className="flex flex-col gap-y-1.5">
                                        <label className="font-medium text-black">
                                            Password
                                        </label>
                                        <div className="relative">
                                            <LockedIcon
                                                className={`absolute left-3 top-1/2 -translate-y-1/2 transform text-gray-600 ${
                                                    errors.password
                                                        ? "fill-[#E03616]"
                                                        : "fill-[#444444]"
                                                }`}
                                            />
                                            <Field
                                                placeholder="Password"
                                                className={`placeholder-gray w-full rounded-md border border-[#444444] border-opacity-25 p-2.5 pl-8 text-[14px] font-medium placeholder-opacity-25 placeholder:text-[14px] placeholder:font-medium focus:outline-primary ${
                                                    errors.password &&
                                                    "border-[#E03616] border-opacity-100"
                                                }`}
                                                type={
                                                    passwordVisible
                                                        ? "text"
                                                        : "password"
                                                }
                                                name="password"
                                                validate={validatePassword}
                                            />
                                            {passwordVisible ? (
                                                <div
                                                    onClick={
                                                        togglePasswordVisibility
                                                    }
                                                >
                                                    <EyeOffIcon
                                                        className={`absolute right-3 top-1/2 -translate-y-1/2 transform cursor-pointer ${
                                                            errors.password
                                                                ? "fill-[#E03616]"
                                                                : "fill-[#444444]"
                                                        }`}
                                                    />
                                                </div>
                                            ) : (
                                                <div
                                                    onClick={
                                                        togglePasswordVisibility
                                                    }
                                                >
                                                    <EyeIcon
                                                        className={`absolute right-3 top-1/2 -translate-y-1/2 transform cursor-pointer ${
                                                            errors.password
                                                                ? "fill-[#E03616]"
                                                                : "fill-[#444444]"
                                                        }`}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        {errors.password &&
                                            touched.password && (
                                                <div className="text-[12px] font-medium text-[#E03616]">
                                                    {errors.password}
                                                </div>
                                            )}
                                    </div>
                                    <div className="flex flex-col gap-y-5">
                                        <div className="text-[14px] font-medium text-primary">
                                            <NavLink to="/user/forgot-password">
                                                Lupa Password
                                            </NavLink>
                                            <br />
                                        </div>

                                        <button className="button button--primary">
                                            <span className="spinner d-inline-block">
                                                <span className="bounce1" />
                                                <span className="bounce2" />
                                                <span className="bounce3" />
                                            </span>
                                            <span className="px-5 py-3 font-bold text-white">
                                                LogIn
                                            </span>
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    )}
                </div>
                <div className="relative flex w-full items-center justify-center rounded-r-2xl bg-primary">
                    <div className="absolute left-[50%] top-[50%] h-1/2 w-1/2 -translate-x-1/2 -translate-y-1/2 transform rounded-full bg-white/25 blur-2xl " />
                    <img
                        alt="shadow-laptop"
                        className="relative w-1/2"
                        src={laptopShadow}
                    />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ authUser }) => {
    const { auth } = authUser;

    return { auth };
};

export default connect(mapStateToProps, {
    loginUser: authSet,
})(Login);
