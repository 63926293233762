import React from "react";

const SvgIcon = ({
  width = 22,
  height = 19,
  className = "fill-[#444444]",
  opacity = "0.25",
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.9995 16.3507C10.9455 16.3507 11.8095 16.2499 12.5974 16.0757L10.8405 14.3562C10.5675 14.3767 10.2905 14.3934 9.9995 14.3934C4.64863 14.3934 2.57567 10.6294 2.07369 9.5C2.45062 8.67675 2.95971 7.91782 3.58165 7.25198L2.18368 5.88379C0.64572 7.51525 0.0627333 9.15844 0.0517336 9.19074C-0.0172445 9.39172 -0.0172445 9.60926 0.0517336 9.81024C0.0727331 9.87483 2.36668 16.3507 9.9995 16.3507ZM9.9995 2.64927C8.16254 2.64927 6.65358 3.03683 5.39561 3.60935L1.70669 0L0.292728 1.38385L18.2923 19L19.7063 17.6162L16.3874 14.3679C19.0013 12.4585 19.9343 9.85134 19.9483 9.81024C20.0172 9.60926 20.0172 9.39172 19.9483 9.19074C19.9263 9.12517 17.6323 2.64927 9.9995 2.64927ZM14.9714 12.9821L12.6914 10.7507C12.8814 10.3691 12.9994 9.94921 12.9994 9.5C12.9994 7.89399 11.6405 6.56397 9.9995 6.56397C9.54051 6.56397 9.11152 6.67946 8.72253 6.86638L6.91457 5.09694C7.90707 4.76362 8.95023 4.59782 9.9995 4.60662C15.3504 4.60662 17.4233 8.37061 17.9253 9.5C17.6233 10.1772 16.7593 11.7921 14.9714 12.9821Z"
        fillOpacity={opacity}
      />
    </svg>
  );
};

export default SvgIcon;
