import React, {useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import {Field, Form, Formik} from "formik";
import {NotificationManager} from "../../components/react-notifications";
import {requestData} from "../../config/request";
import notainLogo from "../../assets/logo/notain-logo.png";
import laptopShadow from "../../assets/illustration/login/laptop.png";
import {CircleXIcon, EmailIcon} from "../../assets/icon";

const validateEmail = (value) => {
    let error;
    if (!value) {
        error = "Please enter your email address";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        error = "Invalid email address";
    }
    return error;
};

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [messageError, setMessageError] = useState("");
    const navigate = useNavigate();

    const resetPass = (values) => {
        if (!loading) {
            if (values.email !== "") {
                setLoading(true);
                setEmail(values.email);

                requestData("user/resetPass", { email: values.email }).then(
                    (response) => {
                        if (response.success) {
                            NotificationManager.primary(
                                response.message,
                                "success",
                                3000,
                                null,
                                null,
                                "",
                            );
                            navigate("/user/login");
                        } else {
                            setMessageError(response.message);
                        }

                        setLoading(false);
                    },
                );
            }
        }
    };

    const initialValues = { email };

    return (
        <div className="container mx-auto w-full justify-center p-[70px]">
            <div className="flex flex-row rounded-2xl bg-white">
                <div className="flex w-full flex-col overflow-auto px-20 py-10">
                    <div className="flex justify-center">
                        <img
                            width={161}
                            height={49}
                            src={notainLogo}
                            alt="logo"
                        />
                    </div>
                    <div className="mb-3.5 mt-4 flex items-center justify-center">
                        {messageError ? (
                            <div className="flex flex-row gap-x-1.5 rounded-md border border-[#E03616] border-opacity-25 bg-[#E03616] bg-opacity-10 px-3.5 py-1.5">
                                <CircleXIcon />
                                <div className="text-[14px] font-medium text-[#E03616]">
                                    {messageError}
                                </div>
                            </div>
                        ) : (
                            <div className="font-bold text-black">
                                Lupa Password Anda?
                            </div>
                        )}
                    </div>
                    <div className="login-form">
                        <NavLink to="/" className="white">
                            <span className="logo-single" />
                        </NavLink>
                        <div className="col-span-12 text-center">
                            <p className="mb-0 text-md text-black">
                                Untuk mengatur ulang password anda, silahkan
                            </p>
                            <p className="mb-0 text-md text-black">
                                masukkan alamat email yang terdaftar. Periksa
                                email
                            </p>
                            <p className="mb-0 text-md text-black">
                                anda untuk mengatur ulang password anda.
                            </p>
                        </div>
                        <div className="pt-[15px]">
                            <Formik
                                initialValues={initialValues}
                                onSubmit={resetPass}
                            >
                                {({ errors, touched }) => (
                                    <Form className="flex flex-col">
                                        <div className="gap-y-1.5 px-16">
                                            <div className="flex flex-col gap-y-1.5">
                                                <div className="relative">
                                                    <EmailIcon
                                                        opacity="0.25"
                                                        className={`absolute left-3 top-1/2 -translate-y-1/2 transform text-gray-600 ${
                                                            errors.email
                                                                ? "fill-[#E03616]"
                                                                : "fill-[#444444]"
                                                        }`}
                                                        width={16}
                                                        height={18}
                                                    />
                                                    <Field
                                                        placeholder="Email Address"
                                                        className={`placeholder-gray w-full rounded-md border border-[#444444] border-opacity-25 p-2.5 pl-8 text-[14px] font-medium placeholder-opacity-25 placeholder:text-[14px] placeholder:font-medium focus:outline-primary ${
                                                            errors.email &&
                                                            "border-[#E03616] border-opacity-100"
                                                        }`}
                                                        name="email"
                                                        validate={validateEmail}
                                                    />
                                                </div>
                                            </div>

                                            {errors.email && touched.email && (
                                                <div className="text-[12px] font-medium text-[#E03616]">
                                                    {errors.email}
                                                </div>
                                            )}
                                        </div>
                                        <div className="flex justify-center gap-4 pt-[15px]">
                                            <NavLink
                                                className="button flex items-center justify-center border border-gray-400 bg-white px-20 text-gray-800"
                                                to="/user/login"
                                            >
                                                Batal
                                            </NavLink>
                                            <button
                                                color="primary"
                                                className={`button button--primary px-12 ${
                                                    loading
                                                        ? "show-spinner"
                                                        : ""
                                                }`}
                                            >
                                                <span className="spinner d-inline-block">
                                                    <span className="bounce1" />
                                                    <span className="bounce2" />
                                                    <span className="bounce3" />
                                                </span>
                                                <span className="label">
                                                    Reset Password
                                                </span>
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
                <div className="relative flex w-full items-center justify-center rounded-r-2xl bg-primary">
                    <div className="absolute left-[50%] top-[50%] h-1/2 w-1/2 -translate-x-1/2 -translate-y-1/2 transform rounded-full bg-white/25 blur-2xl " />
                    <img
                        alt="shadow-laptop"
                        className="relative w-1/2"
                        src={laptopShadow}
                    />
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
