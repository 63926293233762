import React from "react";

const SvgIcon = ({
  width = 14,
  height = 14,
  className = "fill-[#444444]",
  fill = "#444444",
  opacity = "0.25",
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9598 2.03704C11.3148 1.38879 10.5466 0.874806 9.69985 0.525076C8.85313 0.175345 7.94491 -0.00313607 7.02814 4.16986e-05C3.18693 4.16986e-05 0.0562815 3.11503 0.0562815 6.93702C0.0562815 8.16202 0.3799 9.35201 0.984925 10.402L0 14L3.69347 13.034C4.71357 13.587 5.8603 13.881 7.02814 13.881C10.8693 13.881 14 10.766 14 6.94402C14 5.08903 13.2754 3.34603 11.9598 2.03704ZM7.02814 12.705C5.98693 12.705 4.96683 12.425 4.07337 11.9L3.86231 11.774L1.66734 12.348L2.25126 10.22L2.11055 10.003C1.53208 9.08389 1.22492 8.02149 1.22412 6.93702C1.22412 3.75903 3.82714 1.16904 7.0211 1.16904C8.56884 1.16904 10.0251 1.77104 11.1156 2.86303C11.6555 3.39781 12.0834 4.03389 12.3744 4.73443C12.6655 5.43496 12.8139 6.186 12.8111 6.94402C12.8251 10.122 10.2221 12.705 7.02814 12.705ZM10.208 8.39302C10.0322 8.30902 9.17387 7.88902 9.01909 7.82602C8.85729 7.77002 8.74472 7.74202 8.62512 7.91002C8.50553 8.08502 8.17487 8.47702 8.07638 8.58902C7.97789 8.70802 7.87236 8.72202 7.69648 8.63102C7.5206 8.54702 6.95779 8.35802 6.29648 7.77002C5.77588 7.30802 5.43116 6.74102 5.32563 6.56602C5.22714 6.39102 5.31156 6.30002 5.40301 6.20902C5.4804 6.13202 5.57889 6.00602 5.66332 5.90802C5.74774 5.81002 5.78291 5.73302 5.8392 5.62102C5.89548 5.50202 5.86734 5.40403 5.82513 5.32003C5.78291 5.23603 5.43116 4.38203 5.29045 4.03203C5.14975 3.69603 5.00201 3.73803 4.89648 3.73103H4.55879C4.4392 3.73103 4.25628 3.77303 4.09447 3.94803C3.9397 4.12303 3.48945 4.54303 3.48945 5.39703C3.48945 6.25102 4.11558 7.07702 4.2 7.18902C4.28442 7.30802 5.43116 9.05801 7.17588 9.80701C7.59095 9.98901 7.91457 10.094 8.16784 10.171C8.58291 10.304 8.96281 10.283 9.26533 10.241C9.60301 10.192 10.2995 9.82101 10.4402 9.41501C10.5879 9.00901 10.5879 8.66602 10.5387 8.58902C10.4894 8.51202 10.3839 8.47702 10.208 8.39302Z"
        fill={fill}
        fillOpacity={opacity}
      />
    </svg>
  );
};

export default SvgIcon;
