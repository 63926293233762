import React from "react";

const SvgIcon = ({
  width = 14,
  height = 14,
  className = "fill-[#444444]",
  fill = "#444444",
  opacity = "0.25",
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.908 12.7679C13.0193 11.1512 11.6316 9.89141 9.96832 9.19146C10.7955 8.54842 11.4064 7.65191 11.7147 6.62892C12.023 5.60593 12.0129 4.50833 11.686 3.49159C11.359 2.47485 10.7318 1.59052 9.89301 0.963867C9.05425 0.337211 8.04655 0 7.01263 0C5.97872 0 4.97101 0.337211 4.13226 0.963867C3.29351 1.59052 2.66622 2.47485 2.33927 3.49159C2.01232 4.50833 2.00227 5.60593 2.31055 6.62892C2.61883 7.65191 3.22981 8.54842 4.05694 9.19146C2.39366 9.89141 1.00599 11.1512 0.117313 12.7679C0.0622476 12.8597 0.0255464 12.962 0.00939189 13.0688C-0.00676267 13.1756 -0.00204092 13.2847 0.0232765 13.3895C0.048594 13.4943 0.0939903 13.5929 0.156767 13.6792C0.219543 13.7655 0.298418 13.8378 0.388702 13.8919C0.478986 13.9459 0.578836 13.9806 0.682317 13.9939C0.785798 14.0072 0.890796 13.9987 0.99107 13.9691C1.09134 13.9395 1.18485 13.8892 1.26602 13.8214C1.34719 13.7536 1.41438 13.6695 1.46358 13.5742C2.63808 11.4698 4.71225 10.2147 7.01263 10.2147C9.31302 10.2147 11.3872 11.4705 12.5617 13.5742C12.6683 13.7519 12.8375 13.8797 13.0333 13.9305C13.2292 13.9814 13.4365 13.9512 13.6113 13.8465C13.7861 13.7418 13.9148 13.5707 13.9702 13.3695C14.0256 13.1682 14.0032 12.9525 13.908 12.7679ZM3.64211 5.10838C3.64211 4.41737 3.83978 3.74187 4.21014 3.16731C4.5805 2.59276 5.1069 2.14494 5.72279 1.8805C6.33867 1.61606 7.01637 1.54688 7.67019 1.68169C8.32401 1.8165 8.92458 2.14925 9.39595 2.63787C9.86733 3.12649 10.1883 3.74903 10.3184 4.42677C10.4484 5.10451 10.3817 5.807 10.1266 6.44541C9.87148 7.08383 9.43948 7.62949 8.8852 8.0134C8.33092 8.3973 7.67926 8.60221 7.01263 8.60221C6.11903 8.60115 5.26232 8.2327 4.63045 7.57771C3.99857 6.92273 3.64314 6.03468 3.64211 5.10838Z"
        fill={fill}
        fillOpacity={opacity}
      />
    </svg>
  );
};

export default SvgIcon;
