import React from "react";
import {CMS_VER} from "../../config/host";

const Footer = ({ id, ref }) => {
    return (
        <footer
            id={id}
            ref={ref}
            className="mt-auto bg-primary px-6 py-4 text-black"
        >
            {/* Footer content */}
            <div className="flex justify-center">
                <div className="font-bold text-white">Versi {CMS_VER}</div>
            </div>
        </footer>
    );
};

export default Footer;
