import React, {Component} from "react";
import {NavLink} from "react-router-dom";
import {requestData} from "../../config/request";
import {CircleXIcon, EmailIcon, EyeIcon, EyeOffIcon, LockedIcon, PersonIcon, WhatsappIcon,} from "../../assets/icon";
import {Field, Form, Formik} from "formik";
import {NotificationManager} from "../../components/react-notifications";
import notainLogo from "../../assets/logo/notain-logo.png";
import laptopShadow from "../../assets/illustration/login/laptop.png";

const validateName = (value) => {
    let error;

    if (!value) error = "Nama harus diisi";

    return error;
};
const validateNick = (value) => {
    let error;

    if (!value) error = "Nama panggilan harus diisi";

    return error;
};
const validateEmail = (value) => {
    let error;

    if (!value) error = "Masukkan alamat email anda";
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value))
        error = "Email tidak valid";

    return error;
};
const validatePhone = (value) => {
    let error;

    if (!value) error = "Nomor telepon harap diisi";
    else if (!/^[0-9+]+$/.test(value)) error = "Nomor telepon tidak valid";

    return error;
};
const validatePassword = (value) => {
    let error;

    if (!value) error = "Masukkan password anda";
    else if (value.length < 4) error = "Password minimal 8 karakter";

    return error;
};
const validatePasswordConf = (value) => {
    let error;

    if (!value) error = "Masukkan Konfirmasi password anda";
    else if (value.length < 8)
        error =
            "Password minimal 8 karakter, terdapat angka, huruf kapital, dan huruf kecil!";

    return error;
};

class Register extends Component {
    constructor() {
        super();

        this.state = {
            confirm: false,
            messageError: "",
            name: "",
            nick_name: "",
            email: "",
            phone: "",
            password: "",
            passwordConf: "",
            passwordVisible: false,
            passwordConfVisible: false,
            agreeTerms: false,
        };
    }

    togglePasswordVisibility = () => {
        this.setState((prevState) => ({
            passwordVisible: !prevState.passwordVisible,
        }));
    };
    togglePasswordConfVisibility = () => {
        this.setState((prevState) => ({
            passwordConfVisible: !prevState.passwordConfVisible,
        }));
    };
    handleCheckboxChange = (event) => {
        const { target } = event;
        const { name, checked } = target;

        this.setState({
            [name]: checked,
        });
    };

    simpanData = (value) => {
        const form = {
            name: value.name,
            nick_name: value.nick_name,
            email: value.email,
            phone: value.phone,
            password: value.password,
            password_conf: value.passwordConf,
        };
        requestData("account/register", form, "PATCH").then((response) => {
            if (response.success) {
                NotificationManager.success(
                    response.message,
                    "Berhasil!",
                    3000,
                    null,
                    null,
                    "",
                );
                this.setState({ confirm: true, messageError: "" });
                this.props.history.push("/user/login");
            } else {
                if (response?.errors?.length >= 1) {
                    response.errors.map((item) =>
                        NotificationManager.error(
                            item.message,
                            "Berhasil!",
                            3000,
                            null,
                            null,
                            "",
                        ),
                    );
                } else {
                    NotificationManager.error(
                        response.message,
                        "Berhasil!",
                        3000,
                        null,
                        null,
                        "",
                    );
                    this.setState({ messageError: response.message });
                }
            }
        });
    };

    render() {
        const {
            name,
            email,
            phone,
            password,
            passwordConf,
            nick_name,
            agreeTerms,
        } = this.state;
        const initialValues = {
            name,
            email,
            nick_name,
            phone,
            password,
            passwordConf,
        };

        return (
            <div className="container mx-auto w-full justify-center p-[30px] lg:p-[70px]">
                <div className="flex flex-row rounded-2xl bg-white ">
                    <div className="flex w-full flex-col overflow-auto px-10 py-10 lg:px-20">
                        <div className="flex justify-center">
                            <img
                                width={161}
                                height={49}
                                src={notainLogo}
                                alt="logo"
                            />
                        </div>
                        <div className="mb-3.5 mt-4 flex items-center justify-center">
                            {this.state.messageError ? (
                                <div className="flex flex-row gap-x-1.5 rounded-md border border-[#E03616] border-opacity-25 bg-[#E03616] bg-opacity-10 px-3.5 py-1.5">
                                    <CircleXIcon />
                                    <div className="text-[14px] font-medium text-[#E03616]">
                                        {this.state.messageError}
                                    </div>
                                </div>
                            ) : (
                                <label className="font-bold text-black">
                                    Daftar Akun
                                </label>
                            )}
                        </div>
                        <Formik
                            initialValues={initialValues}
                            onSubmit={this.simpanData}
                            validateOnBlur={false}
                            validateOnChange={false}
                        >
                            {({ errors, touched }) => (
                                <Form className="flex flex-col">
                                    <div className="gap-y-1.5">
                                        <div className="flex flex-col gap-y-1.5">
                                            <label className="font-medium text-black">
                                                Nama Lengkap
                                            </label>
                                            <div className="relative">
                                                <PersonIcon
                                                    opacity="0.25"
                                                    className={`absolute left-3 top-1/2 -translate-y-1/2 transform text-gray-600`}
                                                    width={14}
                                                    height={14}
                                                />
                                                <Field
                                                    placeholder="Nama Lengkap"
                                                    className={`placeholder-gray w-full rounded-md border border-[#444444] border-opacity-25 p-2.5 pl-8 text-[14px] font-medium placeholder-opacity-25 placeholder:text-[14px] placeholder:font-medium focus:outline-primary ${
                                                        errors.name &&
                                                        "border-[#E03616] border-opacity-100"
                                                    }`}
                                                    name="name"
                                                    validate={validateName}
                                                />
                                            </div>
                                        </div>
                                        {errors.name && touched.name && (
                                            <div className="text-[12px] font-medium text-[#E03616]">
                                                {errors.name}
                                            </div>
                                        )}

                                        <div className="flex flex-col gap-y-1.5">
                                            <label className="font-medium text-black">
                                                Nama Panggilan
                                            </label>
                                            <div className="relative">
                                                <PersonIcon
                                                    opacity="0.25"
                                                    className={`absolute left-3 top-1/2 -translate-y-1/2 transform text-gray-600`}
                                                    width={14}
                                                    height={14}
                                                />
                                                <Field
                                                    placeholder="Nama Panggilan"
                                                    className={`placeholder-gray w-full rounded-md border border-[#444444] border-opacity-25 p-2.5 pl-8 text-[14px] font-medium placeholder-opacity-25 placeholder:text-[14px] placeholder:font-medium focus:outline-primary ${
                                                        errors.name &&
                                                        "border-[#E03616] border-opacity-100"
                                                    }`}
                                                    name="nick_name"
                                                    validate={validateNick}
                                                />
                                            </div>
                                        </div>
                                        {errors.nick_name &&
                                            touched.nick_name && (
                                                <div className="text-[12px] font-medium text-[#E03616]">
                                                    {errors.nick_name}
                                                </div>
                                            )}

                                        <div className="flex flex-col gap-y-1.5">
                                            <label className="font-medium text-black">
                                                Email
                                            </label>
                                            <div className="relative">
                                                <EmailIcon
                                                    opacity="0.25"
                                                    className={`absolute left-3 top-1/2 -translate-y-1/2 transform text-gray-600 ${
                                                        errors.email
                                                            ? "fill-[#E03616]"
                                                            : "fill-[#444444]"
                                                    }`}
                                                    width={16}
                                                    height={18}
                                                />
                                                <Field
                                                    placeholder="Email Adress"
                                                    className={`placeholder-gray w-full rounded-md border border-[#444444] border-opacity-25 p-2.5 pl-8 text-[14px] font-medium placeholder-opacity-25 placeholder:text-[14px] placeholder:font-medium focus:outline-primary ${
                                                        errors.email &&
                                                        "border-[#E03616] border-opacity-100"
                                                    }`}
                                                    name="email"
                                                    validate={validateEmail}
                                                />
                                            </div>
                                        </div>
                                        {errors.email && touched.email && (
                                            <div className="text-[12px] font-medium text-[#E03616]">
                                                {errors.email}
                                            </div>
                                        )}
                                    </div>

                                    <div className="flex flex-col gap-y-1.5">
                                        <label className="font-medium text-black">
                                            Nomor Telepon Aktif
                                        </label>
                                        <div className="relative">
                                            <WhatsappIcon
                                                opacity="0.25"
                                                className={`absolute left-3 top-1/2 -translate-y-1/2 transform text-gray-600 ${
                                                    errors.phone
                                                        ? "fill-[#E03616]"
                                                        : "fill-[#444444]"
                                                }`}
                                                width={16}
                                                height={18}
                                            />
                                            <Field
                                                placeholder="Nomor Telepon Aktif"
                                                className={`placeholder-gray w-full rounded-md border border-[#444444] border-opacity-25 p-2.5 pl-8 text-[14px] font-medium placeholder-opacity-25 placeholder:text-[14px] placeholder:font-medium focus:outline-primary ${
                                                    errors.email &&
                                                    "border-[#E03616] border-opacity-100"
                                                }`}
                                                name="phone"
                                                validate={validatePhone}
                                            />
                                        </div>
                                    </div>
                                    {errors.phone && touched.phone && (
                                        <div className="text-[12px] font-medium text-[#E03616]">
                                            {errors.phone}
                                        </div>
                                    )}

                                    <div className="flex flex-col gap-y-1.5">
                                        <label className="font-medium text-black">
                                            Password
                                        </label>
                                        <div className="relative">
                                            <LockedIcon
                                                className={`absolute left-3 top-1/2 -translate-y-1/2 transform text-gray-600 ${
                                                    errors.password
                                                        ? "fill-[#E03616]"
                                                        : "fill-[#444444]"
                                                }`}
                                            />
                                            <Field
                                                placeholder="Password"
                                                className={`placeholder-gray w-full rounded-md border border-[#444444] border-opacity-25 p-2.5 pl-8 text-[14px] font-medium placeholder-opacity-25 placeholder:text-[14px] placeholder:font-medium focus:outline-primary ${
                                                    errors.password &&
                                                    "border-[#E03616] border-opacity-100"
                                                }`}
                                                type={
                                                    this.state.passwordVisible
                                                        ? "text"
                                                        : "password"
                                                }
                                                name="password"
                                                validate={validatePassword}
                                            />
                                            <div
                                                onClick={
                                                    this
                                                        .togglePasswordVisibility
                                                }
                                            >
                                                {this.state.passwordVisible ? (
                                                    <EyeOffIcon
                                                        className={`absolute right-3 top-1/2 -translate-y-1/2 transform cursor-pointer ${
                                                            errors.password
                                                                ? "fill-[#E03616]"
                                                                : "fill-[#444444]"
                                                        }`}
                                                    />
                                                ) : (
                                                    <EyeIcon
                                                        className={`absolute right-3 top-1/2 -translate-y-1/2 transform cursor-pointer ${
                                                            errors.password
                                                                ? "fill-[#E03616]"
                                                                : "fill-[#444444]"
                                                        }`}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        {errors.password &&
                                            touched.password && (
                                                <div className="text-[12px] font-medium text-[#E03616]">
                                                    {errors.password}
                                                </div>
                                            )}
                                    </div>

                                    <div className="flex flex-col gap-y-1.5">
                                        <label className="font-medium text-black">
                                            Konfirmasi Password
                                        </label>
                                        <div className="relative">
                                            <LockedIcon
                                                className={`absolute left-3 top-1/2 -translate-y-1/2 transform text-gray-600 ${
                                                    errors.passwordConf
                                                        ? "fill-[#E03616]"
                                                        : "fill-[#444444]"
                                                }`}
                                            />
                                            <Field
                                                placeholder="Konfirmasi Password"
                                                className={`placeholder-gray w-full rounded-md border border-[#444444] border-opacity-25 p-2.5 pl-8 text-[14px] font-medium placeholder-opacity-25 placeholder:text-[14px] placeholder:font-medium focus:outline-primary ${
                                                    errors.passwordConf &&
                                                    "border-[#E03616] border-opacity-100"
                                                }`}
                                                type={
                                                    this.state
                                                        .passwordConfVisible
                                                        ? "text"
                                                        : "password"
                                                }
                                                name="passwordConf"
                                                validate={validatePasswordConf}
                                            />
                                            <div
                                                onClick={
                                                    this
                                                        .togglePasswordConfVisibility
                                                }
                                            >
                                                {this.state
                                                    .passwordConfVisible ? (
                                                    <EyeOffIcon
                                                        className={`absolute right-3 top-1/2 -translate-y-1/2 transform cursor-pointer ${
                                                            errors.passwordConf
                                                                ? "fill-[#E03616]"
                                                                : "fill-[#444444]"
                                                        }`}
                                                    />
                                                ) : (
                                                    <EyeIcon
                                                        className={`absolute right-3 top-1/2 -translate-y-1/2 transform cursor-pointer ${
                                                            errors.passwordConf
                                                                ? "fill-[#E03616]"
                                                                : "fill-[#444444]"
                                                        }`}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        {errors.passwordConf &&
                                            touched.passwordConf && (
                                                <div className="text-[12px] font-medium text-[#E03616]">
                                                    {errors.passwordConf}
                                                </div>
                                            )}
                                    </div>

                                    <div className="flex flex-col gap-y-5">
                                        <div className="text-[14px] font-medium text-primary">
                                            <NavLink to="/user/forgot-password">
                                                Lupa Password
                                            </NavLink>
                                        </div>

                                        <div className="flex items-center">
                                            <input
                                                type="checkbox"
                                                className="mr-2"
                                                id="agreeTerms"
                                                name="agreeTerms"
                                                checked={agreeTerms}
                                                onChange={
                                                    this.handleCheckboxChange
                                                }
                                            />
                                            <label
                                                htmlFor="agreeTerms"
                                                className="text-[14px] font-medium text-black"
                                            >
                                                <div>
                                                    Saya telah membaca dan
                                                    menyetujui
                                                </div>
                                                <div className="text-[14px] font-medium text-primary">
                                                    <NavLink to="/kebijakan-privasi">
                                                        Syarat Ketentuan &
                                                        Kebijakan Privasi
                                                    </NavLink>
                                                </div>
                                            </label>
                                        </div>

                                        <button
                                            className="button button--primary py-4 "
                                            disabled={!agreeTerms}
                                        >
                                            <span className="spinner d-inline-block">
                                                <span className="bounce1" />
                                                <span className="bounce2" />
                                                <span className="bounce3" />
                                            </span>
                                            <span className="px-5 py-3 font-bold text-white">
                                                Daftar Sekarang
                                            </span>
                                        </button>
                                        <div className="flex flex-row justify-center text-primary">
                                            <div className="font-medium text-black">
                                                Sudah Memiliki Akun?
                                            </div>
                                            <NavLink to="/user/login">
                                                Masuk
                                            </NavLink>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                    <div className="relative flex w-full items-center justify-center rounded-r-2xl bg-primary">
                        <div className="absolute left-[50%] top-[50%] h-1/2 w-1/2 -translate-x-1/2 -translate-y-1/2 transform rounded-full bg-white/25 blur-2xl " />
                        <img
                            alt="shadow-laptop"
                            className="relative w-1/2"
                            src={laptopShadow}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default Register;
