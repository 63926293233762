//src/redux/auth/actions.js

import {AUTH_CLEAR, AUTH_INIT, AUTH_SET,} from "../actions";

export const authInit = (auth, token, user) => ({
    type: AUTH_INIT,
    payload: { auth, token, user },
});

export const authSet = (token, user) => ({
    type: AUTH_SET,
    payload: { token, user },
});

export const authClear = () => ({
    type: AUTH_CLEAR,
});