import React from "react";

const SvgIcon = ({ width = 14, height = 18, className = "fill-[#444444]" }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 13.7143C6.53587 13.7143 6.09075 13.5337 5.76256 13.2122C5.43437 12.8907 5.25 12.4547 5.25 12C5.25 11.0486 6.02875 10.2857 7 10.2857C7.46413 10.2857 7.90925 10.4663 8.23744 10.7878C8.56563 11.1093 8.75 11.5453 8.75 12C8.75 12.4547 8.56563 12.8907 8.23744 13.2122C7.90925 13.5337 7.46413 13.7143 7 13.7143ZM12.25 16.2857V7.71429H1.75V16.2857H12.25ZM12.25 6C12.7141 6 13.1592 6.18061 13.4874 6.5021C13.8156 6.82359 14 7.25963 14 7.71429V16.2857C14 16.7404 13.8156 17.1764 13.4874 17.4979C13.1592 17.8194 12.7141 18 12.25 18H1.75C1.28587 18 0.840752 17.8194 0.512563 17.4979C0.184374 17.1764 0 16.7404 0 16.2857V7.71429C0 6.76286 0.77875 6 1.75 6H2.625V4.28571C2.625 3.14907 3.08594 2.05898 3.90641 1.25526C4.72688 0.451529 5.83968 0 7 0C7.57453 0 8.14344 0.110853 8.67424 0.326231C9.20504 0.541608 9.68734 0.857291 10.0936 1.25526C10.4998 1.65322 10.8221 2.12568 11.042 2.64564C11.2618 3.16561 11.375 3.72291 11.375 4.28571V6H12.25ZM7 1.71429C6.30381 1.71429 5.63613 1.9852 5.14384 2.46744C4.65156 2.94968 4.375 3.60373 4.375 4.28571V6H9.625V4.28571C9.625 3.60373 9.34844 2.94968 8.85616 2.46744C8.36387 1.9852 7.69619 1.71429 7 1.71429Z"
        fillOpacity="0.25"
      />
    </svg>
  );
};

export default SvgIcon;
