import React, {useEffect, useRef, useState} from "react";
import SubMenu from "./SubMenus";
import {motion} from "framer-motion";
import {useMediaQuery} from "react-responsive";
import {NavLink, useLocation} from "react-router-dom";
import notainLogoMobile from "../../../assets/logo/notain-mobile.png";

const Sidebar = ({ menuItems, toggle, show }) => {
    let isTabletMid = useMediaQuery({ query: "(max-width: 768px)" });
    const sidebarRef = useRef();
    const { pathname } = useLocation();
    const [openSubMenu, setOpenSubMenu] = useState(null);

    useEffect(() => {
        if (isTabletMid) {
            toggle();
        }
    }, [isTabletMid]);

    useEffect(() => {
        isTabletMid && toggle();
    }, [pathname]);

    const Nav_animation = isTabletMid
        ? {
              open: {
                  x: 0,
                  width: "16rem",
                  transition: {
                      damping: 40,
                  },
              },
              closed: {
                  x: -250,
                  width: 0,
                  transition: {
                      damping: 40,
                      delay: 0.15,
                  },
              },
          }
        : {
              open: {
                  width: "16rem",
                  transition: {
                      damping: 40,
                  },
              },
              closed: {
                  width: "4rem",
                  transition: {
                      damping: 40,
                  },
              },
          };

    const page = pathname.split("/");

    return (
        <div>
            <div
                onClick={() => toggle()}
                className={`fixed inset-0 z-[998] max-h-screen bg-black/50 md:hidden ${
                    show ? "block" : "hidden"
                } `}
            ></div>
            <motion.div
                ref={sidebarRef}
                variants={Nav_animation}
                initial={{ x: isTabletMid ? -250 : 0 }}
                animate={show ? "open" : "closed"}
                style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }}
                className="text-gray fixed z-[999] h-screen w-[16rem] max-w-[16rem] overflow-hidden bg-white "
            >
                <div
                    className="border-slate-300 flex h-[67px] items-center gap-3 bg-transparent px-3 py-4 font-medium"
                    style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }}
                >
                    <img src={notainLogoMobile} width={40} alt="" />
                    <span className="whitespace-pre text-2xl font-bold text-primary">
                        Nota<span className="text-black">in</span> Admin
                    </span>
                </div>

                <div className="flex h-full flex-col">
                    <ul className="scrollbar-thin scrollbar-track-white scrollbar-thumb-slate-100 flex h-[calc(100%-66px)] flex-col gap-1 overflow-x-hidden whitespace-pre px-2.5 py-5 text-[0.9rem] font-medium">
                        {menuItems.map((item, index) => (
                            <React.Fragment key={index}>
                                {item.subs && item.subs?.length === 0 ? (
                                    <li key={index}>
                                        <NavLink
                                            to={`${item.to}`}
                                            className='link'
                                        >
                                            <div>{item.icon}</div>
                                            {item.label}
                                        </NavLink>
                                    </li>
                                ) : (
                                    (show || isTabletMid) && (
                                        <div
                                            key={index}
                                            className="flex flex-col gap-1"
                                        >
                                            <SubMenu
                                                data={item}
                                                isOpen={openSubMenu === index}
                                                onToggle={() =>
                                                    setOpenSubMenu((prev) =>
                                                        prev === index
                                                            ? null
                                                            : index,
                                                    )
                                                }
                                            />
                                        </div>
                                    )
                                )}
                            </React.Fragment>
                        ))}
                    </ul>
                </div>
            </motion.div>
        </div>
    );
};

export default Sidebar;
