import React, {useState} from "react";
import {ArrowsPointingInIcon, ArrowsPointingOutIcon, PowerIcon,} from "@heroicons/react/16/solid";
import {connect} from "react-redux";
import {authClear} from "../../redux/actions";
import userCircle from "../../assets/userCircle.png";
import {Menu, MenuHandler, MenuItem, MenuList} from "@material-tailwind/react";
import {useNavigate} from "react-router-dom";

const Header = ({ toggle,user, logoutUserAction }) => {
    const navigate = useNavigate()
    const [isInFullScreen, setIsInFullScreen] = useState(false);

    const isInFullScreenFn = () => {
        return ((document.fullscreenElement && true) || (document.webkitFullscreenElement && true) || (document.mozFullScreenElement && true) || (document.msFullscreenElement && true));
    };

    const toggleFullScreen = () => {
        const isFS = isInFullScreenFn();

        const docElm = document.documentElement;
        if (!isFS) {
            if (docElm.requestFullscreen) {
                docElm.requestFullscreen();
            } else if (docElm.mozRequestFullScreen) {
                docElm.mozRequestFullScreen();
            } else if (docElm.webkitRequestFullScreen) {
                docElm.webkitRequestFullScreen();
            } else if (docElm.msRequestFullscreen) {
                docElm.msRequestFullscreen();
            }
        } else if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
        setIsInFullScreen(!isFS);
    };

    const handleLogout = () => {
        logoutUserAction();
        navigate("user/login");
    };

    return (
        <header
            className="border-slate-300 sticky top-0 z-40 flex items-center justify-between border-b bg-white px-6 py-4 text-black"
            style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }}
        >
            <div
                className="cursor-pointer"
                onClick={() => {
                    toggle();
                }}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-6 w-6"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                        stroke="#8f8f8f"
                    />
                </svg>
            </div>

            <div className="flex w-full flex-row items-center justify-end gap-x-1.5">
                <button
                    className="mr-2 inline-block h-6 bg-transparent"
                    type="button"
                    id="fullScreenButton"
                    onClick={toggleFullScreen}
                >
                    {isInFullScreen ? (
                        <ArrowsPointingInIcon className="h-6 text-gray-400 hover:text-primary-light"/>
                    ) : (
                        <ArrowsPointingOutIcon className="h-6 text-gray-400 hover:text-primary-light"/>
                    )}
                </button>

                <Menu>
                    <MenuHandler>
                        <div className="flex cursor-pointer items-center gap-x-1.5">
                            <div className="text-gray text-xs font-semibold text-opacity-75">
                                {user?.name}
                            </div>
                            <img alt="logo" width={35} src={userCircle} />
                        </div>
                    </MenuHandler>
                    <MenuList>
                        <MenuItem
                            className="flex gap-x-1.5"
                            onClick={() => {
                                handleLogout()
                            }}
                        >
                            <PowerIcon className="h-4 w-4" />
                            Log Out
                        </MenuItem>
                    </MenuList>
                </Menu>
            </div>
        </header>
    );
};

const mapStateToProps = ({ authUser }) => {
    const { user } = authUser;

    return { user };
};

const mapActionsToProps = {
    logoutUserAction: authClear
};

export default connect(mapStateToProps, mapActionsToProps)(Header);
