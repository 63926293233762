import React from "react";

const SvgIcon = ({ width = 20, height = 20, color = "#E03616" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1633 8.16328L11.3281 10L13.1656 11.8367C13.3417 12.0128 13.4407 12.2517 13.4407 12.5008C13.4407 12.7499 13.3417 12.9887 13.1656 13.1648C12.9895 13.341 12.7506 13.4399 12.5016 13.4399C12.2525 13.4399 12.0136 13.341 11.8375 13.1648L10 11.3281L8.16328 13.1656C7.98716 13.3417 7.74829 13.4407 7.49922 13.4407C7.25015 13.4407 7.01128 13.3417 6.83516 13.1656C6.65904 12.9895 6.5601 12.7506 6.5601 12.5016C6.5601 12.2525 6.65904 12.0136 6.83516 11.8375L8.67188 10L6.83672 8.16328C6.74952 8.07608 6.68034 7.97255 6.63315 7.85861C6.58595 7.74467 6.56166 7.62255 6.56166 7.49922C6.56166 7.25015 6.6606 7.01128 6.83672 6.83516C7.01284 6.65904 7.25171 6.56009 7.50078 6.56009C7.74986 6.56009 7.98873 6.65904 8.16485 6.83516L10 8.67188L11.8367 6.83438C12.0128 6.65826 12.2517 6.55931 12.5008 6.55931C12.7499 6.55931 12.9887 6.65826 13.1648 6.83438C13.341 7.0105 13.4399 7.24937 13.4399 7.49844C13.4399 7.74751 13.341 7.98638 13.1648 8.1625L13.1633 8.16328ZM18.4375 10C18.4375 11.6688 17.9427 13.3001 17.0155 14.6876C16.0884 16.0752 14.7706 17.1566 13.2289 17.7952C11.6871 18.4338 9.99064 18.6009 8.35393 18.2754C6.71721 17.9498 5.2138 17.1462 4.03379 15.9662C2.85379 14.7862 2.05019 13.2828 1.72463 11.6461C1.39907 10.0094 1.56616 8.31286 2.20477 6.77111C2.84338 5.22936 3.92484 3.9116 5.31238 2.98448C6.69992 2.05735 8.33122 1.5625 10 1.5625C12.237 1.56498 14.3817 2.45473 15.9635 4.03653C17.5453 5.61833 18.435 7.763 18.4375 10ZM16.5625 10C16.5625 8.70206 16.1776 7.43327 15.4565 6.35407C14.7354 5.27487 13.7105 4.43374 12.5114 3.93704C11.3122 3.44034 9.99272 3.31038 8.71972 3.5636C7.44672 3.81681 6.2774 4.44183 5.35962 5.35961C4.44183 6.27739 3.81682 7.44672 3.5636 8.71972C3.31038 9.99272 3.44034 11.3122 3.93704 12.5114C4.43374 13.7105 5.27488 14.7354 6.35407 15.4565C7.43327 16.1776 8.70206 16.5625 10 16.5625C11.7399 16.5606 13.408 15.8686 14.6383 14.6383C15.8686 13.408 16.5606 11.7399 16.5625 10Z"
        fill={color}
      />
    </svg>
  );
};

export default SvgIcon;
