import React, {useEffect, useState} from "react";
import {motion} from "framer-motion";
import {NavLink, useLocation} from "react-router-dom";
import {ChevronDownIcon} from "@heroicons/react/24/outline";

const SubMenus = ({ data, isOpen, onToggle }) => {
    const { pathname } = useLocation();
    const [thirdMenuOpen, setThirdMenuOpen] = useState(false);
    const [anySubMenuActive, setAnySubMenuActive] = useState(false);

    const isSubMenuActive = () => {
        const page = pathname.split("/");

        for (let i = 0; i < data.subs?.length; i++) {
            const submenu = data.subs[i];

            if (submenu.to && page[3] == submenu.to) {
                return true;
            }
            if (submenu.subs && submenu.subs.length > 0) {
                for (let j = 0; j < submenu.subs.length; j++) {
                    const subsubmenu = submenu.subs[j];
                    if (subsubmenu.to && page[3] == subsubmenu.to) {
                        return true;
                    }
                }
            }
        }
        return false;
    };

    useEffect(() => {
        setAnySubMenuActive(isSubMenuActive());
    }, [pathname]);

    return (
        <>
            <li
                key={data.label}
                className={`link ${anySubMenuActive && "active"}`}
                onClick={onToggle}
            >
                <div>{data.icon}</div>

                <p className="flex-1 capitalize">{data.label}</p>
                <ChevronDownIcon
                    className={`${isOpen && "rotate-180"} h-4 w-4 duration-200 `}
                />
            </li>
            <motion.div
                animate={isOpen ? { height: "fit-content" } : { height: 0 }}
                className="flex h-0 flex-col overflow-hidden pl-[2.9rem] text-[0.8rem] font-normal"
            >
                {data.subs?.map((menu, idx) => (
                    <li key={idx}>
                        {menu.subs && menu.subs.length == 0 ? (
                            <NavLink
                                id="subMenu"
                                to={`${menu.to}`}
                                className="link !bg-transparent capitalize"
                            >
                                <div>{menu.icon}</div>
                                {menu.label}
                            </NavLink>
                        ) : (
                            <>
                                <li
                                    className={`link ${
                                        pathname.includes(menu.label) &&
                                        "text-purple-600"
                                    }`}
                                    onClick={() =>
                                        setThirdMenuOpen(!thirdMenuOpen)
                                    }
                                >
                                    <p className="flex-1 capitalize">
                                        {menu.label}
                                    </p>
                                    <ChevronDownIcon
                                        className={` ${
                                            thirdMenuOpen && "rotate-180"
                                        } h-4 w-4 duration-200 `}
                                    />
                                </li>
                                <motion.div
                                    animate={
                                        thirdMenuOpen
                                            ? { height: "fit-content" }
                                            : { height: 0 }
                                    }
                                    className="flex h-0 flex-col overflow-hidden pl-4 text-[0.8rem] font-normal"
                                >
                                    {menu.subs?.map((third, key) => (
                                        <li key={key}>
                                            <NavLink
                                                to={third.to}
                                                className="link !bg-transparent capitalize"
                                            >
                                                {third.label}
                                            </NavLink>
                                        </li>
                                    ))}
                                </motion.div>
                            </>
                        )}
                    </li>
                ))}
            </motion.div>
        </>
    );
};

export default SubMenus;
