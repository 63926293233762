import {BrowserRouter as Router, Navigate, Route, Routes, useNavigate,} from "react-router-dom";
import React, {Suspense, useEffect} from "react";
import {NotificationContainer} from "components/react-notifications";
import Preloader from "components/loader/preloader";
import PrivateRoute from "helpers/authHelper";
import NotFound from "pages/not-found";
import Login from "pages/auth/login";
import Register from "pages/auth/register";
import ForgotPassword from "pages/auth/forgot-password";
import ResetPassword from "pages/auth/reset-password";
import Confirm from "pages/auth/confirm";

const Dashboard = React.lazy(() => import("pages/dashboard"));
const BusinessFieldAdd = React.lazy(() => import("pages/business-fields/add"));
const BusinessFieldDetail = React.lazy(() => import("pages/business-fields/detail"));
const BusinessFieldList = React.lazy(() => import("pages/business-fields/list"));
const BlogAdd = React.lazy(() => import("pages/blog/add"));
const BlogDetail = React.lazy(() => import("pages/blog/detail"));
const BlogList = React.lazy(() => import("pages/blog/list"));
const Company = React.lazy(() => import("pages/company/list"));
const Setting = React.lazy(() => import("pages/setting"));
const PaketAdd = React.lazy(() => import("pages/paket/add"));
const PaketDetail = React.lazy(() => import("pages/paket/detail"));
const PaketList = React.lazy(() => import("pages/paket/list"));
const UserPage = React.lazy(() => import("pages/auth"));
const Account = React.lazy(() => import("pages/account"));
const AccountDetail = React.lazy(() => import("pages/account/detail"));
const UserList = React.lazy(() => import("pages/user/list"));
const UserDetail = React.lazy(() => import("pages/user/detail"));
const UserAdd = React.lazy(() => import("pages/user/add"));
const InboxPage = React.lazy(() => import("pages/inbox/InboxPage"));
const Subscriptions = React.lazy(() => import("pages/subscriptions"))

const AuthChecker = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const Auth = localStorage.getItem("NotainPOS-Admin-Auth");
        const AuthKey = localStorage.getItem("NotainPOS-Admin-Auth-Key");
        const AuthUser = localStorage.getItem("NotainPOS-Admin-Auth-User");

        if (Auth !== null && AuthKey !== null && AuthUser !== null) {
            navigate('/dashboard');
        } else {
            navigate('/user/login');
        }
    }, [navigate]);

    return null;  // Render nothing, just handle redirection
};

const AppMain = () => {
    return (
        <div className="w-full font-public">
            <NotificationContainer />
            <Suspense fallback={<Preloader />}>
                <Router>
                    <Routes>
                        <Route path="/" element={<AuthChecker />} />
                        <Route path="/user" element={<UserPage />} >
                            <Route path="login" element={<Login />} />
                            <Route path="register" element={<Register />} />
                            <Route path="forgot-password" element={<ForgotPassword />} />
                            <Route path="reset-password" element={<ResetPassword />} />
                            <Route path="confirm/:token" element={<Confirm />} />
                            <Route path="*" element={<Navigate to="login" replace />} />
                        </Route>
                        <Route element={<PrivateRoute />}>
                            <Route path="*" element={<NotFound />} />
                            <Route path="/dashboard" element={<Dashboard />} />
                            <Route path="/account" element={<Account />} />
                            <Route path="/account/:id" element={<AccountDetail />} />
                            <Route path="/company/list" element={<Company />} />
                            <Route path="/business-fields/add" element={<BusinessFieldAdd />} />
                            <Route path="/business-fields/:id" element={<BusinessFieldDetail />} />
                            <Route path="/business-fields/list" element={<BusinessFieldList />} />
                            <Route path="/blog/add" element={<BlogAdd />} />
                            <Route path="/blog/list" element={<BlogList />} />
                            <Route path="/blog/:id" element={<BlogDetail />} />
                            <Route path="/user/list" element={<UserList />} />
                            <Route path="/user/:id" element={<UserDetail />} />
                            <Route path="/user/tambah" element={<UserAdd />} />
                            <Route path="/setting" element={<Setting />} />
                            <Route path="/paket/tambah" element={<PaketAdd />} />
                            <Route path="/paket/:id" element={<PaketDetail />} />
                            <Route path="/paket/list" element={<PaketList />} />
                            <Route path="/inbox" element={<InboxPage />} />
                            <Route path="/subscriptions" element={<Subscriptions />} />

                        </Route>
                    </Routes>
                </Router>
            </Suspense>
        </div>
    );
};

export default AppMain;
