import React from "react";
import {Navigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {authClear, authInit} from "../redux/actions";
import AppLayout from "../layouts/app-layouts";
import {requestData} from "../config/request";

const PrivateRoute = () => {
    const dispatch = useDispatch();

    const Auth = localStorage.getItem("NotainPOS-Admin-Auth");
    const AuthKey = localStorage.getItem("NotainPOS-Admin-Auth-Key");
    const AuthUser = localStorage.getItem("NotainPOS-Admin-Auth-User");

    const request = async (action, data, method) => {
        const formData = { token: AuthKey, ...data };

        return requestData(action, formData, method).then((response) => {
            if (response.auth !== undefined && !response.auth) dispatch(authClear());

            return response;
        });
    };

    if (Auth != null && AuthKey != null && AuthUser != null) {
        dispatch(authInit(Auth, AuthKey, JSON.parse(AuthUser)))

        return (
            <AppLayout
                request={(url, body, method) =>
                    request(url, body, method)
                }
            />
        );
    } else {
        // dispatch(authClear());
        return <Navigate to={"/user/login"} />;
    }
};

export default PrivateRoute;
